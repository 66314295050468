@import "../../styles/theme/mixin";

.page{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #f0f2f5;
}

.form{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 30px 10px;
  transform: translate(-50%, -100%);
  h2{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
}