$theme-color1: #f9d8c5;
$theme-color2: #fcbdab;
$theme-color3: #94d183;
$theme-color4: #6ebda8;
$theme-color5: #269493;
$theme-color6: #20485a;

$header-height: 60px;
$primary-color: $theme-color5;
$seconder-color: $theme-color4;
$light-color: $theme-color2;
$text-color: $theme-color6;
$border-color: #e5e5e5;
$color-danger: #f5222d;