.page{
  padding-top: 100px;
}

.icon{
  width: 60%;
  float: left;
  padding-right: 50px;
  img{
    width: 100%;
  }
}

.content{
  width: 40%;
  float: left;
  h1{
    margin-bottom: 24px;
    color: #434e59;
    font-weight: 600;
    font-size: 72px;
    line-height: 72px;
  }
}

.desc{
  margin-bottom: 16px;
  color: rgba(0,0,0,.45);
  font-size: 20px;
  line-height: 28px;
}

.actions{

}