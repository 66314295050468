@import "~react-toastify/dist/ReactToastify.css";
@import "./styles/theme/mixin";

.app{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.del{
  color: $color-danger;
  &:hover{
    color: $color-danger;
  }
}